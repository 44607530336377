'use client';

import { type UrlObject } from 'url';
import { forwardRef, useState } from 'react';
import { mdiClose, mdiMenu, mdiWeatherNight, mdiWeatherSunny } from '@mdi/js';
import Icon from '@mdi/react';
import { cx } from 'class-variance-authority';
import { useHotkeys } from 'react-hotkeys-hook';

import {
  type NavigationMenuItem,
  type NavigationMenuSubItem,
} from '@/src/types/NavigationMenuItem';

import JoinNewsletter from '@/src/blocks/JoinNewsletter';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from '@/src/components/Accordion';
import Divider from '@/src/components/Divider';
import Drawer from '@/src/components/Drawer';
import IconButton from '@/src/components/IconButton';
import Switch from '@/src/components/Switch';
import Tooltip from '@/src/components/Tooltip';
import Typography from '@/src/components/Typography';
import ConditionalWrapper from '@/src/utils/ConditionalWrapper';

import { ReactComponent as AutomobileLogo } from '../../../assets/images/automobile.svg';
import { ReactComponent as FourwheelerLogo } from '../../../assets/images/fourwheeler.svg';
import { ReactComponent as HotRodLogo } from '../../../assets/images/hotrod.svg';
import { ReactComponent as LowriderLogo } from '../../../assets/images/lowrider.svg';
import { ReactComponent as MotorTrendLogo } from '../../../assets/images/motortrend.svg';
import { ReactComponent as SuperStreetLogo } from '../../../assets/images/superstreet.svg';
import { ReactComponent as TruckTrendLogo } from '../../../assets/images/trucktrend.svg';
import { type GlobalHeaderHamburgerMenuProps } from './GlobalHeaderHamburgerMenu.props';
import { GlobalHeaderHamburgerMenuVariants } from './GlobalHeaderHamburgerMenu.variants';

export const GlobalHeaderHamburgerMenu = forwardRef<
  HTMLDivElement,
  GlobalHeaderHamburgerMenuProps
>(
  (
    {
      activePath,
      activeTheme = 'light',
      brand = 'motortrend',
      className,
      items = [],
      joinNewsletterLink,
      linkElement: LinkElement = 'a',
      toggleTheme,
      ...props
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const closeMenu = () => setIsOpen(false);

    useHotkeys('shift+alt+d', () => toggleTheme?.());

    const calculateNavItemClasses = (
      item: NavigationMenuItem | NavigationMenuSubItem,
    ) => {
      const itemPathName =
        (typeof item.href === 'string'
          ? item.href
          : (item.href as UrlObject)?.pathname) || '';
      const isActive = itemPathName === activePath;

      return {
        'border-primary-2': isActive,
        'border-transparent': !isActive,
        'focus-visible:border-primary-2': !isActive,
        'focus-visible:border-primary-3': isActive,
        'hover:border-primary-2': !isActive,
        'hover:border-primary-3': isActive,
        'hover:text-primary-7': !isActive,
      };
    };

    // TODO: These will eventually need to be sourced from Next
    // but are stored here for the time being.
    // See: https://motortrendgroup.atlassian.net/browse/IDS-51
    const availablebrands = [
      {
        id: 'motortrend',
        link: 'https://www.motortrend.com/',
        logo: MotorTrendLogo,
        title: 'MotorTrend',
      },
      {
        id: 'hotrod',
        link: 'https://www.hotrod.com/',
        logo: HotRodLogo,
        title: 'Hot Rod',
      },
      {
        id: 'automobile',
        link: 'https://www.motortrend.com/automobilemag/',
        logo: AutomobileLogo,
        title: 'Automobile',
      },
      {
        id: 'fourwheeler',
        link: 'https://www.motortrend.com/fourwheeler/',
        logo: FourwheelerLogo,
        title: 'Fourwheeler',
      },
      {
        id: 'trucktrend',
        link: 'https://www.motortrend.com/trucktrend/',
        logo: TruckTrendLogo,
        title: 'TruckTrend',
      },
      {
        id: 'superstreet',
        link: 'https://www.motortrend.com/superstreetonline/',
        logo: SuperStreetLogo,
        title: 'Super Street',
      },
      {
        id: 'lowrider',
        link: 'https://www.motortrend.com/lowrider/',
        logo: LowriderLogo,
        title: 'Lowrider',
      },
    ];

    const renderHeaderLogo = () => {
      switch (brand) {
        case 'hotrod':
          return (
            <LinkElement
              className="text-neutral-8 transition-colors hover:text-primary-1"
              data-id="hotrod-logo"
              data-parent="hamburger-menu"
              href="/"
              onClick={closeMenu}
            >
              <HotRodLogo className="w-full max-w-32" title="Hot Rod" />
            </LinkElement>
          );
        default:
          return (
            <LinkElement
              className="text-primary-2 transition-colors hover:text-neutral-8"
              data-id="motortrend-logo"
              data-parent="hamburger-menu"
              href="/"
              onClick={closeMenu}
            >
              <MotorTrendLogo className="w-full max-w-32" title="MotorTrend" />
            </LinkElement>
          );
      }
    };

    const renderSubBrands = () => {
      // Filter out whichever brand is supplied to the component.
      // This is the "main" brand of the header; the rest are subbrands.
      return availablebrands.map(({ id, link, logo: Logo, title }) => {
        if (id === brand) {
          return null;
        }

        return (
          <LinkElement
            className="my-2 flex w-2/4 items-center justify-center p-4 hover:text-neutral-8"
            data-id="hamburger-menu-link"
            data-parent="hamburger-menu"
            href={link}
            key={title}
            onClick={closeMenu}
          >
            <Logo className="max-h-6 min-w-[104px]" title={title} />
          </LinkElement>
        );
      });
    };

    return (
      <div {...props} data-ids="GlobalHeaderHamburgerMenu" ref={ref}>
        <IconButton
          aria-label="Menu"
          colorScheme="transparent"
          data-id="open-hamburger-menu"
          data-parent="hamburger-menu"
          onClick={() => setIsOpen(true)}
          rounded
        >
          <Icon path={mdiMenu} size={1} />
        </IconButton>
        <Drawer
          className={GlobalHeaderHamburgerMenuVariants({ brand, className })}
          onClose={closeMenu}
          open={isOpen}
          theme="dark"
        >
          <div className="w-screen sm:w-[360px]">
            <div className="sticky top-0 z-10 flex items-center justify-between bg-neutral-2/60 py-10 pl-8 pr-6 backdrop-blur-md">
              {renderHeaderLogo()}
              <Tooltip content="Close Menu" side="right" theme="dark">
                <IconButton
                  className="!border-transparent"
                  colorScheme="neutral-3"
                  data-id="close-hamburger-menu"
                  data-parent="hamburger-menu"
                  onClick={closeMenu}
                  rounded
                  variant="outlined"
                >
                  <Icon path={mdiClose} size={1} />
                </IconButton>
              </Tooltip>
            </div>
            {activeTheme && toggleTheme && (
              <div className="mx-8 flex flex-wrap pb-6">
                <Divider className="mb-6" />
                <div className="flex w-full items-center justify-between">
                  <Typography
                    as="p"
                    className="select-none text-neutral-5"
                    disableColorScheme
                    variant="subtitle1"
                  >
                    Theme
                  </Typography>
                  <Tooltip
                    content={
                      <div className="text-center">
                        <Typography disableColorScheme variant="caption1">
                          {`Switch to ${activeTheme === 'dark' ? 'Light' : 'Dark'} Mode`}
                        </Typography>
                        <Typography disableColorScheme variant="caption2">
                          Shift+Alt+D
                        </Typography>
                      </div>
                    }
                    theme="dark"
                  >
                    <div className="flex items-center justify-end gap-4">
                      <Icon
                        className={cx({
                          'text-neutral-5': activeTheme !== 'light',
                        })}
                        path={mdiWeatherSunny}
                        size={1}
                      />
                      <Switch
                        checked={activeTheme === 'light'}
                        className="rotate-180"
                        colorScheme="neutral"
                        data-id="theme-toggle"
                        data-parent="hamburger-menu"
                        onCheckedChange={toggleTheme}
                        size="small"
                      />
                      <Icon
                        className={cx({
                          'text-neutral-5': activeTheme !== 'dark',
                        })}
                        path={mdiWeatherNight}
                        size={1}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
            <div className="mx-8 flex flex-wrap pb-4 text-neutral-5">
              <Divider className="mb-6" />
              {renderSubBrands()}
              <Divider className="my-6" />
            </div>
            <Accordion className="pb-6 text-neutral-5" type="multiple">
              {items.map((item) => {
                const ItemLinkElement = item?.linkElement || LinkElement;
                return (
                  <AccordionItem key={item.title} value={item.title}>
                    <AccordionHeader
                      className={cx(
                        'border-l-4 px-8 ripple data-[state=open]:text-neutral-8',
                        calculateNavItemClasses(item),
                      )}
                      data-id="toggle-hamburger-menu-accordion"
                      data-parent="hamburger-menu"
                      disabled={!item.children?.length}
                      hideIcon={!item.children?.length}
                    >
                      <ConditionalWrapper
                        condition={!!item.href}
                        wrapper={(content) => (
                          <ItemLinkElement
                            data-id="hamburger-menu-link"
                            data-parent="hamburger-menu"
                            href={item.href!}
                            onClick={closeMenu}
                            target={item.target || undefined}
                          >
                            {content}
                          </ItemLinkElement>
                        )}
                      >
                        <Typography
                          as="p"
                          className="hover:text-neutral-8 focus:text-neutral-8"
                          disableColorScheme
                          variant="subtitle1"
                        >
                          {item.title}
                        </Typography>
                      </ConditionalWrapper>
                    </AccordionHeader>
                    {!!item.children?.length && (
                      <AccordionContent>
                        {item.children.map((child) => {
                          const ChildLinkElement =
                            child?.linkElement || LinkElement;
                          return (
                            <ChildLinkElement
                              className={cx(
                                'block border-l-4 px-10 py-1.5 ripple',
                                calculateNavItemClasses(child),
                              )}
                              data-id="hamburger-menu-link"
                              data-parent="hamburger-menu"
                              href={child.href}
                              key={child.title}
                              onClick={closeMenu}
                              target={child.target || undefined}
                            >
                              <Typography
                                className="hover:text-neutral-8 focus:text-neutral-8"
                                disableColorScheme
                                variant="body2"
                              >
                                {child.title}
                              </Typography>
                            </ChildLinkElement>
                          );
                        })}
                      </AccordionContent>
                    )}
                  </AccordionItem>
                );
              })}
            </Accordion>
            {!!joinNewsletterLink && (
              <div className="mx-8 flex flex-wrap pb-4 text-neutral-5">
                <Divider className="mb-8" />
                <JoinNewsletter
                  className="mb-8"
                  data-parent="hamburger-menu"
                  href={joinNewsletterLink}
                  linkElement={LinkElement}
                />
              </div>
            )}
          </div>
        </Drawer>
      </div>
    );
  },
);
GlobalHeaderHamburgerMenu.displayName = 'GlobalHeaderHamburgerMenu';

export default GlobalHeaderHamburgerMenu;
